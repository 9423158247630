(function () {

	var app = angular.module('Plania');

	app.directive('projectInfo', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				viewMode: '=viewMode',
				update: '=update',
                restrictEdit: '=restrictEdit',
				budget: '=budget',
				isModelLoading: '=isModelLoading',
				currentPage: '=currentPage',
				activateAutocomplete: '=activateAutocomplete'
			},
			controller: ['$scope', '$rootScope', 'Repository', 'TranslationService', '$modal', 'AutoCompleteService', controller],
			templateUrl: 'app/project/views/projectInfoDirective.html'
		};
	});

	function controller($scope, $rootScope, repository, translationService, $modal, autoCompleteService) {
		$scope.rootScope = $rootScope;
		$scope.hasVisitedTab = $scope.currentPage === 'info';

        $scope.entityCommentSearch = {
            searchString: "",
            show: false,
            focus: false
		};

		var setProjectPhaseStatuses = function () {
			if ($scope.projectPhases && $scope.projectPhases.length > 0 && $scope.model.ProjectStatus && $scope.model.ProjectStatus.ProjectPhase) {
				var projectPhase = _.find($scope.projectPhases,
					function (o) { return o.Guid === $scope.model.ProjectStatus.ProjectPhase.Guid; });
				$scope.projectPhaseStatuses = projectPhase ? angular.copy(projectPhase.ProjectStatuses) : null;
			}
		};

		repository.GetPaginated(repository.apiData.projectPhase.url, -1, -1, { 'IndexPosition': 'asc' }, { PropertyFilter: [{ Property: 'GuidProject', Operator: '=', Value: $scope.model.Guid }] }, '', JSON.stringify(['Id', 'IndexPosition'])).then(function (result) {
			$scope.projectPhases = result.List;
			setProjectPhaseStatuses();
		});

		$scope.onProjectCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidProjectCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidProjectCategory = category.Guid;
		};

		$scope.setProjectPhase = function (phase) {
			if ($scope.model.ProjectStatus &&
				$scope.model.ProjectStatus.ProjectPhase &&
				$scope.model.ProjectStatus.ProjectPhase.Guid === phase.Guid) return;

			$scope.model.GuidProjectStatus = phase.ProjectStatuses[0].Guid;
			$scope.model.ProjectStatus = phase.ProjectStatuses[0];
			$scope.model.ProjectStatus.ProjectPhase = phase;

			$scope.projectPhaseStatuses = angular.copy(phase.ProjectStatuses);

			delete $scope.model.ProjectStatus.ProjectPhase.ProjectStatuses;

			$scope.update();
		};

		var updateProjectStatus = function(status) {
			status.ProjectPhase = $scope.model.ProjectStatus.ProjectPhase;
			$scope.model.GuidProjectStatus = status.Guid;
			// on closing, set specific flag to desambiguate on error processing on return (instead of comparing little reliable error content)
			if (status.Stage === "Closed" && (!$scope.model.ProjectStatus || $scope.model.ProjectStatus.Stage !== "Closed"))
				$scope.model.IsClosing = true;
			$scope.model.ProjectStatus = status;

			$scope.update($scope.model.IsTemplate ? 'projectTemplate.edit' : 'project.edit');
		};

		$scope.setProjectStatus = function (status) {
			if (status.Guid === $scope.model.GuidProjectStatus) return;
			if (status.Stage === 'Closed') {
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-project-stage-close', "Handlingen vil validere om prosjektet er lovlig å lukkes, evtl. aktive, men fullførte arbeidsordre vil sendes til historikk. Et lukket prosjekt kan ikke gjenåpnes. Ønsker du å fortsette?"),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-project-stage-close-confirm', 'Ja, lukk prosjektet'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: true
				}, function () {
					updateProjectStatus(status);
				});

				return; //swal is cancelled
			}
			updateProjectStatus(status);
		};

		//Toggle edit
		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		var updateDateProgress = function () {
			var dateProgress = {
				start: new Date($scope.model.StartDate).getTime(),
				now: new Date().getTime(),
				end: new Date($scope.model.EndDate).getTime(),
				color: 'blue',
				progress: 0
			};

			try {
				dateProgress.progress = ((dateProgress.now - dateProgress.start) * 100) / (dateProgress.end - dateProgress.start);
			} catch (ex) {}

			if ($scope.model) {
				if ($scope.model.ClosedDate)
					dateProgress.color = 'green';
				else if (dateProgress.now > dateProgress.end)
					dateProgress.color = 'red';
				else if (dateProgress.progress > ($scope.model.AverageCompletionRate * 100))
					dateProgress.color = 'red';
				else if (dateProgress.progress <= ($scope.model.AverageCompletionRate * 100))
					dateProgress.color = 'green';
			}

			$scope.dateProgress = dateProgress;
		};
		updateDateProgress();

		$scope.GetEstimateType = function (real, estimate) {
			if (!real || !estimate)
				return 'success';
			var factor = real / estimate;

			if (factor > 1)
				return 'danger';

			if (factor > 0.9)
				return 'warning';

			return 'success';
		};

		$scope.getProgressType = function (percent) {
			var type = 'primary';
			if (percent < 10) {
				type = 'danger';
			} else if (percent < 50) {
				type = 'warning';
			} else if (percent < 100) {
				type = 'info';
			} else if (percent === 100) {
				type = 'success';
			}

			return type;
		};

		$scope.getRiskFactorColor = function (value) {
			switch (value) {
				case "Green":
					return "btn-success";
				case "Yellow":
					return "btn-warning";
				case "Red":
					return "btn-danger";
			}
			return "btn-success";
		};

		$scope.riskSelectOptions = [
			{ text: translationService.translate('web-enum-ProjectRiskFactor-green', 'Lav'), value: 'Green' },
			{ text: translationService.translate('web-enum-ProjectRiskFactor-yellow', 'Middels'), value: 'Yellow' },
			{ text: translationService.translate('web-enum-ProjectRiskFactor-red', 'Høy'), value: 'Red' }

		];

		$scope.updateRisk = function (riskType) {

			var riskFactorProperty = riskType + 'Factor';
			var riskFactorCommentProperty = riskType + 'FactorComment';

			$scope.currentRisk = {
				value: $scope.model[riskFactorProperty],
				comment: $scope.model[riskFactorCommentProperty],
				riskType: riskType
			};

			$modal.open({
				templateUrl: 'app/project/views/projectRiskModal.html',
				scope: $scope

			}).result.then(function (riskToSave) {
				$scope.model[riskFactorProperty] = riskToSave.value;
				$scope.model[riskFactorCommentProperty] = riskToSave.comment;
				$scope.update();
			}, function () {
				//cancel
			});
		};

		$scope.selectPerson = function (personProperty, guidProperty) {
			$modal.open({
				templateUrl: 'app/common/views/tableModal.html',
				controller: 'TableModalController',
				resolve: {
					content: function () {
						return {
							header: translationService.translate('web-lookup-title', 'Oppslagsliste'),
							idTitle: translationService.translate('autocomplete-lookup-header-person', 'Navn'),
							data: autoCompleteService.persons,
							params: {},
							singleValue: true
						};
					}
				}
			}).result.then(function (selected) {
				$scope.model[personProperty] = selected.object;
				$scope.model[guidProperty] = selected.guid;
				$scope.update();
			}, function () {

			});
		};

		$scope.removePerson = function (personProperty, guidProperty) {
			delete $scope.model[guidProperty];
			delete $scope.model[personProperty];

			$scope.update();
		};

		var getKpis = function () {
			var totalAverageCompletionPercent = {
				Prefix: 'WorkOrder',
				FromDate: new Date(0).toISOString(),
				ToDate: null,
				DateProperty: null,
				IncludePeriodicTask: false,
				Interval: null,
				PropertyFilter: { Property: 'GuidProject', Operator: '=', Value: $scope.model.Guid } || [],
				Properties: [{
					Property: 'CompletionRate',
					Function: 'average'
				}]
			};

			var totalWorkOrderKpi = {
				Prefix: 'WorkOrder',
				FromDate: new Date(0).toISOString(),
				ToDate: null,
				DateProperty: null,
				IncludePeriodicTask: false,
				Interval: null,
				PropertyFilter: [{ Property: 'GuidProject', Operator: '=', Value: $scope.model.Guid }],
				Properties: [{
					Property: 'EndDate',
					Function: 'count'
				}, {
					Property: 'CompletionRate',
					Function: 'average'
				}]
			};

			var completedWorkOrdersCount = {
				Prefix: 'WorkOrder',
				FromDate: new Date(0).toISOString(),
				ToDate: null,
				DateProperty: null,
				IncludePeriodicTask: false,
				Interval: null,
				PropertyFilter: [{ Property: 'GuidProject', Operator: '=', Value: $scope.model.Guid }, { Property: 'EndDate', Operator: '<>', Value: null }],
				Properties: [{
					Property: 'EndDate',
					Function: 'count'
				}]
			};

			var hoursSum = {
				Prefix: 'WorkOrder',
				FromDate: new Date(0).toISOString(),
				ToDate: null,
				DateProperty: null,
				IncludePeriodicTask: false,
				Interval: null,
				PropertyFilter: [{ Property: 'GuidProject', Operator: '=', Value: $scope.model.Guid }],
				Properties: [{
					Property: 'EstimatedTime',
					Function: 'sum'
				}, {
					Property: 'RealTime',
					Function: 'sum'
				}, {
					Property: 'EstimatedCost',
					Function: 'sum'
				}, {
					Property: 'RealCost',
					Function: 'sum'
				}]
			};

			repository.createSingle(repository.apiData.kpi.url, hoursSum).then(function (result) {
				$scope.sumEstimatedtime = Number(result[0].Result[0].Value);
				$scope.sumRealTime = Number(result[0].Result[1].Value);
				$scope.sumEstimatedCost = Number(result[0].Result[2].Value);
				$scope.sumRealCost = Number(result[0].Result[3].Value);
				$scope.maxTime = $scope.sumEstimatedtime > $scope.sumRealTime ? $scope.sumEstimatedtime : $scope.sumRealTime;
				if ($scope.maxTime === 0)
					$scope.maxTime = 100;
				$scope.maxCost = $scope.sumEstimatedCost > $scope.sumRealCost ? $scope.sumEstimatedCost : $scope.sumRealCost;
				if ($scope.maxCost === 0)
					$scope.maxCost = 100;
			});

			repository.createSingle(repository.apiData.kpi.url, totalWorkOrderKpi).then(function (result) {
				$scope.totalWorkOrders = result[0].Result[0].Value;
				$scope.totalAverageCompletionPercent = Number((result[0].Result[1].Value * 100).toFixed(2));
			});

			repository.createSingle(repository.apiData.kpi.url, completedWorkOrdersCount).then(function (result) {
				$scope.completedWorkOrders = result[0].Result[0].Value;
			});
		};

		getKpis();


		$scope.onEstateSelect = function (estate) {
			if (!estate) {
				$scope.model.GuidEstate = "";
				return;
			}

			if ($scope.model.GuidEstate !== estate.Guid) {
				$scope.model.GuidBuilding = "";
				$scope.model.Building = "";
				$scope.model.GuidArea = "";
				$scope.model.Area = "";
			}

			$scope.model.GuidEstate = estate.Guid;
		};

		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.model.GuidBuilding = "";
				return;
			}

			if ($scope.model.GuidBuilding !== building.Guid) {
				$scope.model.GuidArea = "";
				$scope.model.Area = "";
			}

			$scope.model.GuidBuilding = building.Guid;

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "" || $scope.model.GuidEstate !== building.GuidEstate) {
				$scope.model.GuidEstate = building.GuidEstate;
				$scope.model.Estate = { Caption: building.Estate.Id + " - " + building.Estate.Description };
			}

			if (building.CostCenter) {
				$scope.model.CostCenter = { Caption: building.CostCenter.Caption };
				$scope.model.GuidCostCenter = building.GuidCostCenter;
			}
		};

		$scope.onAreaSelect = function (area) {
			if (!area) {
				$scope.model.GuidArea = "";
				return;
			}

			$scope.model.GuidArea = area.Guid;

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "") {
				$scope.model.GuidBuilding = area.GuidBuilding;
				$scope.model.Building = { Caption: area.Building.Id + " - " + area.Building.Description };
			}

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "") {
				$scope.model.GuidEstate = area.GuidEstate || area.Building.Estate.Guid;
				$scope.model.Estate = { Caption: area.Building.Estate.Id + " - " + area.Building.Estate.Description };
			}
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "estate":
					return filter;
				case "building":
					if ($scope.model.IsTemplate)
						return {};
					if ($scope.model.GuidEstate) {
						var estateFieldRule = _.filter($scope.fieldRules, function (o) { return o.FieldName === "GuidEstate"; });
						if (estateFieldRule.length === 1 && estateFieldRule[0].Hidden)
							return filter;
						filter.PropertyFilter = [{ Property: 'GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }];
					}
					return filter;
				case "area":
					filter = { GuidBuilding: $scope.model.GuidBuilding };
					if ($scope.model.GuidEstate) {
						filter.PropertyFilter = [{ Property: 'Building.GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }];
					}
					return filter;
				case "person":
                    return filter;
                case "customer":
                    return filter;
				case "supplier":
					filter.PropertyFilter = [{ Property: 'IsDeactivated', Operator: '=', Value: 'false' }];
					return filter;
			}
		};

		$scope.images = [];

		$scope.uploadImage = function (images) {
			if (images.length < 1) return;
			var modalInstance = $modal.open({
				templateUrl: 'app/common/views/imageUploadModal.html',
				controller: 'ImageUploadModalController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							image: images[0],
							targetSize: 1040,
							areaType: 'rectangle'
						};
					}
				}
			});

			modalInstance.result.then(function (croppedImage) {
				var files = [];
				handleFileUpload(croppedImage);
				//Read Base64 string and add to file object
				function handleFileUpload(file) {
					var reader = new FileReader();
					reader.onload = function (e) {
						var newFile = {
							ImageSrc: e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length),
							Name: file.name,
							GuidProject: $scope.model.Guid,
							size: file.size
						};
						files.push(newFile);
						onLoadEndHandler();
					};
					reader.readAsDataURL(file);
				}

				function onLoadEndHandler(image) {
					files.forEach(function (file) {
						$scope.images.push(file);
					});

					if ($scope.model.Images.length > 0) {
						repository.deleteSingle(repository.apiData.image.url, $scope.model.Images[0].Guid).then(function (result) {
							saveImages();
						});

						$scope.model.Images = [];
					} else {
						saveImages();
					}
				}
			});
        };
		var saveImages = function () {
			if (!$scope.images || $scope.images.length < 1) {
				$rootScope.goBack('project.edit', { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
				return;
			}

			repository.createMultiple(repository.apiData.image.url, $scope.images).then(function (result) {
				repository.growl('Prosjekt har blitt opprettet', 'success');
				repository.commonService.setLastRegisterGuid('project.edit', $scope.model.Guid);
				$rootScope.goBack('project.edit', { guid: $scope.model.Guid, menuGuid: $rootScope.navigation.params.menuGuid });
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		// Watchers
		$scope.$watch('isModelLoading', function (newVal) {
			if (newVal === false) {
				if ($scope.model.ClosedDate)
					$scope.canChangePhase = false;
				else
					$scope.canChangePhase =
						(
							$rootScope.userInfo.guidPerson === $scope.model.GuidManagerPerson ||
							$rootScope.userInfo.guidPerson === $scope.model.GuidOwnerPerson ||
							$rootScope.userInfo.isSystemAdministrator
						);
				updateDateProgress();
				setProjectPhaseStatuses();
			}
		});

		$scope.$watch('currentPage', function (newVal, oldVal) {
			if (newVal === oldVal) return;

			if (newVal === 'info') {
				$scope.telerikInfo.reloadReports = !$scope.telerikInfo.reloadReports;
				$scope.hasVisitedTab = true;
			}
		});


		// Infos
		$scope.telerikInfo = new function () {
			var me = this;
			this.parameters = { GuidProject: $scope.model.Guid };
			this.displayReports = false;
			this.endDate = new Date();
			this.reloadReports = false;

			this.updateTelerikParameters = function () {
				if ($scope.model.IsTemplate) return;
				var endDate = $scope.model.EndDate ? new Date($scope.model.EndDate) : null;
				var startDate = $scope.model.StartDate ? new Date($scope.model.StartDate) : new Date();
				if (endDate === null || endDate === undefined) {
					endDate = $scope.telerikInfo.endDate;
				}

				if (endDate.getTime() < startDate.getTime())
					endDate = $scope.model.StartDate;

				$scope.telerikInfo.parameters.StartDate = startDate;
				$scope.telerikInfo.parameters.EndDate = endDate;
				$scope.telerikInfo.displayReports = true;
			};
		}();
	}
})();
