(function () {

	angular.module('Plania').controller('WorkOrderController', ['$scope', 'Repository', '$q', '$stateParams', '$modal', '$modalStack', 'ngTableParams', '$rootScope', '$window', 'TranslationService', '$localStorage', 'ListService', 'EnumService', '$timeout', 'RegisteredFieldService', 'WorkOrderService', 'SignalR', controller]);

	function controller($scope,
		repository,
		$q,
		$stateParams,
		$modal,
		$modalStack,
		ngTableParams,
		$rootScope,
		$window,
		translationService,
		$localStorage,
		listService,
		enumService,
		$timeout,
		registeredFieldService,
		workOrderService,
		signalR) {

		$scope.isUpdate = $scope.navigation.current.name === 'workOrder.edit' || $stateParams.isModal;
		$scope.model = { Guid: $stateParams.guid };
		$scope.activateAutocomplete = false;
		$scope.modelDto = { CompletionRate: 0 };
		var originalStartDate = null;
		$scope.isClosable = true;
		var generatedFrom = repository.commonService.prefix.WorkOrder;
		$scope.isModal = $stateParams.isModal;
		$scope.isTextCollapsed = true;
		$scope.isCommentCollapsed = true;
		$scope.isDynamicPropertiesCollapsed = false;
		$scope.isDocumentsCollapsed = $scope.isModal;
		$scope.reloadOperationalMessages = false;
		$scope.isResourcesCollapsed = true;
		$scope.showToolbar = false;
		$scope.isAreaCollapsed = true;
		$scope.reloads = {
			deviations: false,
			documents: false,
			forceDocuments: false,
			alarmLogs: false,
			controlLists: false,
			economyInfo: false,
		};
		$scope.generalOptions = $localStorage.generalOptions;
		$scope.enableWebNavigation = false;
		$scope.counts = {
			documentTotalCount: 0
		};
		$scope.entityCommentSearch = { searchString: '', focus: false };
		var fieldLengths = {
			Description: 50,
		};

		registeredFieldService.getRegisteredField(repository.commonService.prefix.WorkOrder).then(function (registeredFields) {
			var isExternalUser = $localStorage.userData.isExternalUser;
			var estimatedTimeRule = _.filter(registeredFields, { DomainFieldName: 'EstimatedTime' })[0];
			var realTimeRule = _.filter(registeredFields, { DomainFieldName: 'RealTime' })[0];
			var estimatedCostRule = _.filter(registeredFields, { DomainFieldName: 'EstimatedCost' })[0];
			var realCostRule = _.filter(registeredFields, { DomainFieldName: 'RealCost' })[0];
			$scope.TimeAndCostVisibility = {
				showEstimatedTime: estimatedTimeRule ? (estimatedTimeRule.Hidden ? false : isExternalUser ? !estimatedTimeRule.ExternallyHidden : true) : false,
				showRealTime: realTimeRule ? (realTimeRule.Hidden ? false : isExternalUser ? !realTimeRule.ExternallyHidden : true) : false,
				showEstimatedCost: estimatedCostRule ? (estimatedCostRule.Hidden ? false : isExternalUser ? !estimatedCostRule.ExternallyHidden : true) : false,
				showRealCost: realCostRule ? (realCostRule.Hidden ? false : isExternalUser ? !realCostRule.ExternallyHidden : true) : false
			};
			var descriptionRule = _.filter(registeredFields, { DomainFieldName: 'Description' })[0];
			if (descriptionRule && descriptionRule.MaxLength)
				fieldLengths.Description = descriptionRule.MaxLength;
		});

		registeredFieldService.getRegisteredField(repository.commonService.prefix.WorkOrderXResourceGroup).then(function (registeredFields) {
			var isExternalUser = $localStorage.userData.isExternalUser;
			var estimatedTimeRule = _.filter(registeredFields, { DomainFieldName: 'EstimatedTime' })[0];
			var realTimeRule = _.filter(registeredFields, { DomainFieldName: 'RealTime' })[0];
			var estimatedCostRule = _.filter(registeredFields, { DomainFieldName: 'EstimatedCost' })[0];
			var realCostRule = _.filter(registeredFields, { DomainFieldName: 'RealCost' })[0];
			var noteRule = _.filter(registeredFields, { DomainFieldName: 'Note' })[0];
			$scope.resourceGroupInfo.TimeAndCostVisibility = {
				showEstimatedTime: estimatedTimeRule ? (estimatedTimeRule.Hidden ? false : isExternalUser ? !estimatedTimeRule.ExternallyHidden : true) : false,
				showRealTime: realTimeRule ? (realTimeRule.Hidden ? false : isExternalUser ? !realTimeRule.ExternallyHidden : true) : false,
				showEstimatedCost: estimatedCostRule ? (estimatedCostRule.Hidden ? false : isExternalUser ? !estimatedCostRule.ExternallyHidden : true) : false,
				showRealCost: realCostRule ? (realCostRule.Hidden ? false : isExternalUser ? !realCostRule.ExternallyHidden : true) : false,
				showNote: noteRule ? (noteRule.Hidden ? false : isExternalUser ? !noteRule.ExternallyHidden : true) : false,
			};
			$scope.resourceGroupInfo.initTable();
		});

		var isDirtyEndDate = false;
		$scope.showCompleteButton = $localStorage.generalOptions.WorkorderShowCompleteButton;
		$scope.isEstateDisabled = false;
		$scope.isBuildingDisabled = false;
		$scope.isEquipmentDisabled = false;
		$scope.restrictRedo = true;
		$scope.isHistory = false;
		$scope.editPurchaseOrder = $localStorage.generalOptions.UsePoInWo;
		$scope.viewMode = $scope.isUpdate ? true : false;
		$scope.extendWorkOrderType = 'Ukjent';
		$scope.documents = [];

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};
		/*WorkOrder type = Info container */
		var getWorkOrderType = function () {
			switch ($scope.model.Type) {
				case 'FV':
					return translationService.translate('web-WorkOrder-Type-FV', 'Forebyggende (FV)');
				case 'PR':
					return translationService.translate('web-WorkOrder-Type-PR', 'Prosjekt (PR)');
				case 'ME':
					return translationService.translate('web-WorkOrder-Type-ME', 'Melding (ME)');
				case 'KO':
					return translationService.translate('web-WorkOrder-Type-KO', 'Korrektiv (KO)');
				case 'AV':
					return translationService.translate('web-WorkOrder-Type-AV', 'Avvik (AV)');
				case 'TT':
					return translationService.translate('web-WorkOrder-Type-TT', 'Tiltak (TT)');
				case 'VL':
					return translationService.translate('web-WorkOrder-Type-VL', 'Vaktlogg (VL)');
				case 'TK':
					return translationService.translate('web-WorkOrder-Type-TK', 'Tilstandskontroll (TK)');
				case 'Alarm':
					return translationService.translate('web-WorkOrder-Type-ALARM', 'OPC-Alarm');
				default:
					return 'Ukjent';
			}
		};



		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDo = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictClosedEdit = !$scope.hasEditAccess(repository.commonService.prefix.WorkOrder, undefined, checkOtherDo);
				if ($scope.model.Stage === 'History') {
					$scope.restrictEdit = true;
					$scope.restrictDelete = true;
					$scope.restrictRedo = true;
					$scope.isHistory = true;
					$scope.allowCompletion = false;
				}
				else if ($scope.model.Stage === 'Project' && $scope.model.Project && $scope.model.Project.ClosedDate) {
					$scope.restrictEdit = true;
					$scope.restrictDelete = true;
					$scope.isHistory = true;
				}
				else {
					$scope.restrictEdit = !$scope.hasEditAccess(repository.commonService.prefix.WorkOrder, undefined, checkOtherDo) || ($scope.model.EndDate && !isDirtyEndDate) || false;
					$scope.restrictRedo = !$scope.hasEditAccess(repository.commonService.prefix.WorkOrder, undefined, checkOtherDo);
					$scope.restrictDelete = !$scope.hasDeleteAccess(repository.commonService.prefix.WorkOrder, undefined, checkOtherDo);

					if (!$scope.restrictRedo && $scope.showCompleteButton && $scope.isUpdate) {
						if ($scope.model.EndDate) {
							$scope.restrictRedo = false;
							$scope.allowCompletion = false;
						} else {
							$scope.restrictRedo = true;
							$scope.allowCompletion = true;
						}
					} else {
						$scope.restrictRedo = true;
						$scope.allowCompletion = true;
					}
				}
			} else {
				$scope.restrictEdit = !$scope.hasCreateAccess(repository.commonService.prefix.WorkOrder, null);
				$scope.restrictRedo = true;
				$scope.allowCompletion = false;
			}
		};

		$scope.$watch('model.EndDate', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			if (newValue)
				isDirtyEndDate = true;
			if (oldValue && !newValue)
				isDirtyEndDate = false;
		});

		$scope.getPropertyValue = function (building, column) {
			return listService.GetPropertyValue(building, column);
		};

		// #region cards
		$scope.sparePartInfo = new function () {
			var me = this;

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-sparepart-componentXSupplier-partnumber', 'Delenummer'), Property: 'SparePart.ComponentXSupplier.PartNumber', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-sparepart-component-description', 'Komponentnavn'), Property: 'SparePart.Component.Description', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-sparePart-location', 'Plassering'), Property: 'SparePart.Location', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-workorderxsparepart-Quantity', 'Antall'), Property: 'Quantity', PropertyType: 'number' },
				{ Position: 5, Title: translationService.translate('web-workorderxsparepart-Price', 'Price'), Property: 'Price', PropertyType: 'double' },
				{ Position: 6, Title: translationService.translate('web-workorderxsparepart-QuantityWithdrawn', 'Antall tatt ut'), Property: 'QuantityWithdrawn', PropertyType: 'number' },
				{ Position: 7, Title: translationService.translate('web-sparePart-Inventory', 'Beholdning'), Property: 'SparePart.Inventory', PropertyType: 'number' },
				{ Position: 8, Title: translationService.translate('web-componentXSupplier-supplier-description', 'Leverandør'), Property: 'SparePart.ComponentXSupplier.Supplier.Description', PropertyType: 'string' },
			];

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'UpdatedDate': 'desc' },
					filter: { PropertyFilter: [{ Property: 'GuidWorkOrder', Operator: '=', Value: $scope.model.Guid }] }
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});
							columns.push("IsInvoiced");

							repository.GetPaginated(repository.apiData.workOrderSparePart.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns)).then(
									function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									},
									function (error) {
										repository.growl(error, 'danger');
									});
						}
					});
			};

			this.add = function () {
				var selectedGuids = [];

				me.table.data.forEach(function (sparePart) {
					selectedGuids.push(sparePart.GuidSparePart);
				});

				$modal.open({
					templateUrl: 'app/WorkOrder/views/createWorkOrderXSparePart.html',
					controller: 'CreateWorkOrderXSparePartController',
					size: 'lg',
					resolve: {
						params: function () {
							return {
								isUpdate: false,
								excludeGuids: selectedGuids,
								workOrder: $scope.model
							};
						}
					}
				}).result.then(function (item) {
					if (item && item.GuidSparePart) {
						item.GuidWorkOrder = $scope.model.Guid;
						repository.createSingleDictionary(repository.apiData.workOrderSparePart.url, item)
							.then(function (result) {
								if (!item.QuantityWithdrawn)
									item.QuantityWithdrawn = 0;
								repository.growl(translationService.translate('web-workorder-sparepart-added', 'Reservedel er lagt til arbeidsordre'), 'success');
								me.table.reload();
							});
					}
				}, function () {
					//cancel
				});
			};

			this.update = function (sparePart) {
				if (!me.canEdit(sparePart)) return;
				$modal.open({
					templateUrl: 'app/WorkOrder/views/createWorkOrderXSparePart.html',
					controller: 'CreateWorkOrderXSparePartController',
					resolve: {
						params: function () {
							return {
								isUpdate: true,
								guid: sparePart.Guid,
								workOrder: $scope.model
							};
						}
					}
				}).result.then(function (item) {
					if (item && item.GuidSparePart) {
						item.GuidWorkOrder = $scope.model.Guid;
						item.Guid = sparePart.Guid;
						repository.updateSingleDictionary(repository.apiData.workOrderSparePart.url, item)
							.then(function (result) {
								repository.growl(translationService.translate('web-spare-part-updated', 'Reservedel er oppdatert'), 'success');
								me.table.reload();
							});
					}
				});
			};

			this.remove = function (sparePart) {
				if (!me.canDelete(sparePart)) return;
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-workorder-sparepart-swal-confirmRemove', 'Reservedelen vil bli fjernet fra arbeidsordren'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-sparePart-button-confirm', 'Ja, fjern reservedelen!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.deleteSingle(repository.apiData.workOrderSparePart.url, sparePart.Guid)
						.then(function (result) {
							swal(translationService.translate('web-workorder-swal-sparePartRemoval-success', 'Reservedelen ble fjernet!'), result, "success");
							repository.growl(translationService.translate('web-workorder-sparepart-removed', 'Reservedelen er fjernet fra arbeidsordre'), 'success');
							me.table.reload();
						});
				});
			};

			this.updateWithdrawal = function (sparePart, withdraw) {
				if (!me.canEdit(sparePart)) return;
				$modal.open({
					templateUrl: 'app/WorkOrder/views/withdrawSparePartModal.html',
					controller: 'CreateWorkOrderXSparePartController',
					resolve: {
						params: function () {
							return {
								withdraw: withdraw,
								isUpdate: true,
								guid: sparePart.Guid
							};
						}
					}
				}).result.then(function (item) {
					repository.updateSingleDictionary(repository.apiData.workOrderSparePart.url, item)
						.then(function (result) {
							repository.growl(translationService.translate('web-spare-part-updated', 'Reservedel er oppdatert'), 'success');
							me.table.reload();
						});
				});
			};

			this.contextMenuOptions = function (item) {
				var options = [];

				if (me.canEdit(item)) {
					var editIcon = 'zmdi-edit';
					var editText = translationService.translate('web-workorder-workOrderXSparePart-edit', 'Rediger reservedel');

					var restrictContextEdit = $scope.model.EndDate || $scope.model.Stage === 'History' || ($scope.model.Stage === 'Project' && $scope.model.Project && $scope.model.Project.ClosedDate);

					if (item.IsInvoiced || restrictContextEdit) {
						editIcon = 'zmdi-eye';
						editText = translationService.translate('web-workorder-workOrderXSparePart-open', 'Åpne reservedel');
					}

					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi ' + editIcon + ' zmdi-hc-fw zmdi-hc-lg"></i> ' + editText + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.update(item);
						}
					});

					var withdrawText = translationService.translate('web-workorder-workOrderXSparePart-withdraw', 'Ta ut reservedeler');
					var enableWithdraw = true;

					if ($scope.model.Stage === 'History') {
						enableWithdraw = false;
						withdrawText = translationService.translate('web-workorder-workOrderXSparePart-withdraw-woIsHistory', 'Kan ikke ta ut reservedel når AO er i historikk.');
					} else if ($scope.model.EndDate) {
						enableWithdraw = false;
						withdrawText = translationService.translate('web-workorder-workOrderXSparePart-withdraw-woHasEndDate', 'Kan ikke ta ut reservedel når AO er fullført.');
					} else if ($scope.model.Stage === 'Project' && $scope.model.Project && $scope.model.Project.ClosedDate) {
						enableWithdraw = false;
						withdrawText = translationService.translate('web-workorder-workOrderXSparePart-withdraw-projectIsCompleted', 'Kan ikke ta ut reservedel når AO er koblet til et fullført prosjekt.');
					} else if (item.IsInvoiced) {
						enableWithdraw = false;
						withdrawText = translationService.translate('web-workorder-workOrderXSparePart-withdraw-isInvoiced', 'Kan ikke ta ut fra en fakturert reservedel.');
					}

					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-undo zmdi-hc-fw zmdi-hc-lg"></i> ' + withdrawText + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.updateWithdrawal(item, true);
						},
						enabled: enableWithdraw
					});

					var refillText = translationService.translate('web-workorder-workOrderXSparePart-refill', 'Legg tilbake reservedeler');
					var enableRefill = true;
					if ($scope.model.Stage === 'History') {
						enableRefill = false;
						refillText = translationService.translate('web-workorder-workOrderXSparePart-refill-woIsHistory', 'Kan ikke legge tilbake reservedel når AO er i historikk.');
					} else if ($scope.model.EndDate) {
						enableRefill = false;
						refillText = translationService.translate('web-workorder-workOrderXSparePart-refill-woHasEndDate', 'Kan ikke legge tilbake reservedel når AO er fullført.');
					} else if ($scope.model.Stage === 'Project' && $scope.model.Project && $scope.model.Project.ClosedDate) {
						enableRefill = false;
						refillText = translationService.translate('web-workorder-workOrderXSparePart-refill-projectIsCompleted', 'Kan ikke legge tilbake reservedel når AO er koblet til et fullført prosjekt.');
					} else if (item.IsInvoiced) {
						enableRefill = false;
						refillText = translationService.translate('web-workorder-workOrderXSparePart-refill-isInvoiced', 'Kan ikke legge tilbake fra en fakturert reservedel.');
					}

					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-redo zmdi-hc-fw zmdi-hc-lg"></i> ' + refillText + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.updateWithdrawal(item, false);
						},
						enabled: enableRefill
					});
				}

				if (me.canDelete(item)) {
					var deleteText = translationService.translate('web-workorder-workOrderXSparePart-delete', 'Slett reservedel');
					var enableDelete = true;
					if ($scope.model.Stage === 'History') {
						enableDelete = false;
						deleteText = translationService.translate('web-workorder-workOrderXSparePart-delete-woIsHistory', 'Kan ikke slette reservedel når AO er i historikk.');
					} else if ($scope.model.EndDate) {
						enableDelete = false;
						deleteText = translationService.translate('web-workorder-workOrderXSparePart-delete-woHasEndDate', 'Kan ikke slette reservedel når AO er fullført.');
					} else if ($scope.model.Stage === 'Project' && $scope.model.Project && $scope.model.Project.ClosedDate) {
						enableDelete = false;
						deleteText = translationService.translate('web-workorder-workOrderXSparePart-delete-projectIsCompleted', 'Kan ikke slette reservedel når AO er koblet til et fullført prosjekt.');
					} else if (item.IsInvoiced) {
						enableDelete = false;
						deleteText = translationService.translate('web-workorder-workOrderXSparePart-delete-isInvoiced', 'Kan ikke slette fakturert reservedel');
					}

					options.push({
						hasTopDivider: true,
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + deleteText + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.remove(item);
						},
						enabled: enableDelete
					});
				}
				return options;
			};

			this.canEdit = function (workOrderXSparePart) {
				return workOrderXSparePart &&
					$scope.hasEditAccess('WorkOrderXSparePart');
			};

			this.canDelete = function (workOrderXSparePart) {
				return workOrderXSparePart &&
					$scope.hasDeleteAccess('WorkOrderXSparePart');
			};

		}();

		$scope.resourceGroupInfo = new function () {
			var me = this;

			var setColumns = function () {
				me.columns = [
					{ Position: 1, Title: translationService.translate('web-WorkOrderXResourceGroup-resourceGroup', 'Ressurs'), Property: 'ResourceGroup.Id', PropertyType: 'string' },
					{ Position: 2, Title: translationService.translate('web-WorkOrderXResourceGroup-person', 'Person'), Property: 'Person.FirstName', PropertyType: 'string' },
				];

				if (me.TimeAndCostVisibility.showEstimatedCost)
					me.columns.push({ Position: me.columns.length + 1, Title: translationService.translate('web-WorkOrderXResourceGroup-estimatedCost', 'Est. Kost'), Property: 'EstimatedCost', PropertyType: 'double' });

				if (me.TimeAndCostVisibility.showEstimatedTime)
					me.columns.push({ Position: me.columns.length + 1, Title: translationService.translate('web-WorkOrderXResourceGroup-estimatedTime', 'Est. Tid'), Property: 'EstimatedTime', PropertyType: 'double' });

				if (me.TimeAndCostVisibility.showRealCost)
					me.columns.push({ Position: me.columns.length + 1, Title: translationService.translate('web-WorkOrderXResourceGroup-realCost', 'Kost'), Property: 'RealCost', PropertyType: 'double' });

				if (me.TimeAndCostVisibility.showRealTime)
					me.columns.push({ Position: me.columns.length + 1, Title: translationService.translate('web-WorkOrderXResourceGroup-realTime', 'Tid'), Property: 'RealTime', PropertyType: 'double' });

				if (me.TimeAndCostVisibility.showNote && !me.TimeAndCostVisibility.showRealTime)
					me.columns.push({ Position: me.columns.length + 1, Title: translationService.translate('web-WorkOrderXResourceGroup-note', 'Kommentar'), Property: 'Note', PropertyType: 'string' });

				// Registered date as last
				me.columns.push({ Position: me.columns.length + 1, Title: translationService.translate('web-WorkOrderXResourceGroup-registeredDate', 'Registrert Dato'), Property: 'RegisteredDate', PropertyType: 'date' });
			};

			this.initTable = function () {
				if (me.table) return;
				setColumns();
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'ResourceGroup.Id': 'asc' },
					filter: { PropertyFilter: [{ Property: 'GuidWorkOrder', Operator: '=', Value: $scope.model.Guid }] }
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});

							columns.push('Person.LastName');
							columns.push('ResourceGroup.Description');
							columns.push('ResourceGroup.Guid');
							columns.push("IsInvoiced");

							if (!me.TimeAndCostVisibility.showEstimatedCost)
								columns.push('EstimatedCost');

							if (!me.TimeAndCostVisibility.showEstimatedTime)
								columns.push('EstimatedTime');

							if (!me.TimeAndCostVisibility.showRealCost)
								columns.push('RealCost');

							if (!me.TimeAndCostVisibility.showRealTime)
								columns.push('RealTime');

							//if (!me.TimeAndCostVisibility.showNote && me.TimeAndCostVisibility.showRealTime)
							columns.push('Note');

							repository.GetPaginated(repository.apiData.workOrderXResourceGroup.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns))
								.then(function (result) {
									me.table.settings().total = result.TotalCount;
									me.table.settings().filterDelay = 500;
									$defer.resolve(result.List);
								}
								);
						}
					});
			};

			var getResourceGroup = function () {
				if (!$scope.model.ResourceGroup) return null;
				$scope.model.ResourceGroup.Guid = $scope.model.GuidResourceGroup;
				return $scope.model.ResourceGroup;
			};

			this.add = function () {
				$modal.open({
					templateUrl: 'app/workOrder/views/editWorkOrderXResourceGroupModal.html',
					controller: 'WorkOrderXResourceGroupController',
					resolve: {
						params: function () {
							return {
								GuidWorkOrder: $scope.model.Guid,
								GuidProject: $scope.model.GuidProject,
								ResourceGroup: getResourceGroup()
							};
						}
					}
				}).result.then(function (cost) {
					me.table.reload();
				});
			};

			this.update = function (resource) {
				if (!me.canEdit(resource)) return;
				$modal.open({
					templateUrl: 'app/workOrder/views/editWorkOrderXResourceGroupModal.html',
					controller: 'WorkOrderXResourceGroupController',
					resolve: {
						params: function () {
							return {
								GuidWorkOrderXResourceGroup: resource.Guid
							};
						}
					}
				}).result.then(function (cost) {
					me.table.reload();
				});
			};

			this.remove = function (resource) {
				if (!me.canDelete(resource)) return;
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-resource-message', 'Ressursen vil bli permanent fjernet!'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-resource-button-confirm', 'Ja, fjern ressursen!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.deleteSingle(repository.apiData.workOrderXResourceGroup.url, resource.Guid)
						.then(function (result) {
							swal(translationService.translate('web-swal-resource-success', 'Ressursen ble fjernet!'), result, "success");
							repository.growl(translationService.translate('web-workorder-resoursgroup-removed', 'Ressurs er fjernet fra arbeidsordre'), 'success');
							me.table.reload();
						});
				});
			};


			this.contextMenuOptions = function (item) {
				var options = [];

				if (me.canEdit(item)) {
					var editIcon = 'zmdi-edit';
					var editText = translationService.translate('web-workorder-workOrderXResourceGroup-edit', 'Rediger ressurs');

					var restrictContextEdit = $scope.model.EndDate || $scope.model.Stage === 'History' || ($scope.model.Stage === 'Project' && $scope.model.Project && $scope.model.Project.ClosedDate);

					if (item.IsInvoiced || restrictContextEdit) {
						editIcon = 'zmdi-eye';
						editText = translationService.translate('web-workorder-workOrderXResourceGroup-open', 'Åpne ressurs');
					}

					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi ' + editIcon + ' zmdi-hc-fw zmdi-hc-lg"></i> ' + editText + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.update(item);
						}
					});
				}

				if (me.canDelete(item)) {
					var deleteText = translationService.translate('web-workorder-workOrderXResourceGroup-delete', 'Slett ressurs');
					var enableDelete = true;
					if ($scope.model.Stage === 'History') {
						enableDelete = false;
						deleteText = translationService.translate('web-workorder-workOrderXResourceGroup-delete-woIsHistory', 'Kan ikke slette ressurs når AO er i historikk.');
					} else if ($scope.model.EndDate) {
						enableDelete = false;
						deleteText = translationService.translate('web-workorder-workOrderXResourceGroup-delete-woHasEndDate', 'Kan ikke slette ressurs når AO er fullført.');
					} else if ($scope.model.Stage === 'Project' && $scope.model.Project && $scope.model.Project.ClosedDate) {
						enableDelete = false;
						deleteText = translationService.translate('web-workorder-workOrderXResourceGroup-delete-projectIsCompleted', 'Kan ikke slette ressurs når AO er koblet til et fullført prosjekt.');
					} else if (item.IsInvoiced) {
						enableDelete = false;
						deleteText = translationService.translate('web-workorder-workOrderXResourceGroup-delete-isInvoiced', 'Kan ikke slette fakturert ressurs');
					}

					options.push({
						hasTopDivider: true,
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + deleteText + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.remove(item);
						},
						enabled: enableDelete
					});
				}
				return options;
			};

			this.canEdit = function (workOrderXResourceGroup) {
				return workOrderXResourceGroup &&
					$scope.hasEditAccess('WorkOrderXResourceGroup');
			};

			this.canDelete = function (workOrderXResourceGroup) {
				return workOrderXResourceGroup &&
					$scope.hasDeleteAccess('WorkOrderXResourceGroup');
			};

		}();

		$scope.purchaseOrderItemInfo = new function () {
			var me = this;

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-purchaseOrderItem-Id'), Property: 'Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-purchaseOrderItem-Description'), Property: 'Description', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-supplier-Description', 'Leverandør navn'), Property: 'Supplier.Description', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-purchaseOrderItem-OrderedQuantity', 'Bestilt antall'), Property: 'OrderedQuantity', PropertyType: 'number' },
				{ Position: 5, Title: translationService.translate('web-purchaseOrderItem-UnitPrice', 'Enhetspris'), Property: 'UnitPrice', PropertyType: 'double' },
				{ Position: 6, Title: translationService.translate('web-purchaseOrderItem-OrderedAmount', 'Bestilt beløp'), Property: 'OrderedAmount', PropertyType: 'double' },
				{ Position: 7, Title: translationService.translate('web-purchaseOrderItem-Status', 'Status'), Property: 'Status', PropertyType: 'enum.PurchaseOrderItemStatus' },
			];

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'Id': 'desc' },
					filter: { PropertyFilter: [{ Property: 'GuidWorkOrder', Operator: '=', Value: $scope.model.Guid }] }
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});

							columns.push('PurchaseOrder.Id');
							columns.push("IsInvoiced");

							repository.GetPaginated(repository.apiData.purchaseOrderItem.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								'',
								JSON.stringify(columns))
								.then(function (result) {
									me.table.settings().total = result.TotalCount;
									me.table.settings().filterDelay = 500;
									$defer.resolve(result.List);
								}
								);
						}
					});
			};

			this.canEdit = function (purchaseOrderItem) {
				return purchaseOrderItem &&
					$scope.hasEditAccess('PurchaseOrderItem');
			};

			this.canDelete = function (purchaseOrderItem) {
				return purchaseOrderItem &&
					$scope.hasDeleteAccess('PurchaseOrderItem') &&
					purchaseOrderItem.Status === 'Proposal';
			};

			this.canCreateCost = function (purchaseOrderItem) {
				return purchaseOrderItem &&
					$scope.hasCreateAccess('Cost') &&
					purchaseOrderItem.Status !== 'Proposal' &&
					purchaseOrderItem.Status !== 'Closed';
			};

			var openAddModal = function () {
				$modal.open({
					templateUrl: 'app/purchaseOrder/views/purchaseOrderItemModal.html',
					controller: 'PurchaseOrderItemModalController',
					size: 'md',
					resolve: {
						params: function () {
							var guidCostCenter;
							var costCenterCaption;
							if ($scope.model.Area && $scope.model.Area.GuidCostCenter) {
								guidCostCenter = $scope.model.Area.GuidCostCenter;
								costCenterCaption = $scope.model.Area.CostCenter.Caption;
							}

							else if ($scope.model.Building && $scope.model.Building.GuidCostCenter) {
								guidCostCenter = $scope.model.Building.GuidCostCenter;
								costCenterCaption = $scope.model.Building.CostCenter.Caption;
							}

							else if ($scope.model.Estate && $scope.model.Estate.GuidCostCenter) {
								guidCostCenter = $scope.model.Estate.GuidCostCenter;
								costCenterCaption = $scope.model.Estate.CostCenter.Caption;
							}

							return {
								purchaseOrderItem: {
									GuidWorkOrder: $scope.model.Guid,
									GuidDataOwner: repository.commonService.getFilterData().selectedDataOwner.Guid,
									GuidBuilding: $scope.model.GuidBuilding,
									GuidSupplier: $scope.model.GuidSupplier,
									Supplier: $scope.model.Supplier,
									GuidDepartment: $scope.model.GuidDepartment,
									GuidCostCenter: guidCostCenter,
								},
								costCenterCaption: costCenterCaption
							};
						}
					}
				}).result.then(function (result) {
					me.table.reload();
				});
			};

			this.add = function () {
				if (!$scope.model.StartDate) {
					swal(translationService.translate('web-swal-workorder-addPurchaseOrderItemError', 'Feil ved opprettelse av bestillingsobjekt'),
						translationService.translate('web-swal-workorder-addPurchaseOrderItem-ErrorMessage', 'Det er ikke mulig å legge til bestillingsobjekt på en arbeidsordre uten startdato'),
						"warning");
				} else if (originalStartDate !== $scope.model.StartDate) {
					updateEntity().then(
						function (result) {
							openAddModal();
						}).catch(error);
				} else {
					openAddModal();
				}
			};

			this.update = function (purchaseOrderItem) {
				if (!me.canEdit(purchaseOrderItem)) return;
				$modal.open({
					templateUrl: 'app/purchaseOrder/views/purchaseOrderItemModal.html',
					controller: 'PurchaseOrderItemModalController',
					size: 'xl',
					resolve: {
						params: function () {
							return {
								Guid: purchaseOrderItem.Guid
							};
						}
					}
				}).result.then(function (result) {
					me.table.reload();
				});
			};

			this.remove = function (purchaseOrderItem) {
				if (!me.canDelete(purchaseOrderItem)) return;
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-purchaseOrderItem-message', 'Bestilling objektet vil bli permanent fjernet!'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-purchaseOrderItem-button-confirm', 'Ja, fjern bestilling objektet!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.deleteSingle(repository.apiData.purchaseOrderItem.url, purchaseOrderItem.Guid)
						.then(function (result) {
							swal(translationService.translate('web-swal-purchaseOrderItem-success', 'Bestilling objektet ble fjernet!'), result, "success");
							repository.growl(translationService.translate('web-workorder-purchaseorder-removed', 'Bestilling objektet er fjernet fra bestillingen'), 'success');
							me.table.reload();
						});
				});
			};

			this.createCost = function (purchaseOrderItem) {
				if (!me.canCreateCost(purchaseOrderItem))
					return;

				// inspired from the code that childEntitiesDirective would have run on cost creation (but passing GuidPurchaseOrderItem instead of GuidWorkOrder)
				// (calling modal version would have spared a whole reload of work order, but is does not seem possible to use same controller/view for modal and nonmodal)
				var persistedData = {};
				persistedData.GuidPurchaseOrderItem = purchaseOrderItem.Guid;
				persistedData.PurchaseOrderItem = purchaseOrderItem.Caption;

				repository.persistedData.setPersistedData('cost.create', persistedData);

				$scope.$parent.navigation.go('cost.create', { showPersistantData: true }, { reload: true });

				//$modal.open({
				//	templateUrl: 'app/cost/views/cost.html',
				//	controller: 'CostController',
				//	size: 'xl',
				//	resolve: {
				//		params: function () {
				//			return {
				//				GuidPurchaseOrderItem: purchaseOrderItem.Guid
				//			};
				//		}
				//	}
				//}).result.then(function (result) {
				//	//me.table.reload();
				//});
			};

			this.contextMenuOptions = function (item) {
				var options = [];

				if (me.canEdit(item)) {
					var editIcon = 'zmdi-edit';
					var editText = translationService.translate('web-workorder-purchaseorderitem-edit', 'Rediger konteringsobjekt');

					var restrictContextEdit = $scope.model.EndDate || $scope.model.Stage === 'History' || ($scope.model.Stage === 'Project' && $scope.model.Project && $scope.model.Project.ClosedDate);

					if (item.IsInvoiced || restrictContextEdit) {
						editIcon = 'zmdi-eye';
						editText = translationService.translate('web-workorder-purchaseorderitem-open', 'Åpne konteringsobjekt');
					}

					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi ' + editIcon + ' zmdi-hc-fw zmdi-hc-lg"></i> ' + editText + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.update(item);
						}
					});
				}

				if (item.GuidPurchaseOrder && repository.enableWebNavigation) {
					options.push(getMenuLink($rootScope.navigation.href('purchaseOrder.edit', { guid: item.GuidPurchaseOrder }), 'zmdi-shopping-cart', translationService.translate('web-workorder-viewPurchaseOrder', 'Se bestilling')));
				}

				if (me.canCreateCost(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-receipt zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-workorder-purchaseorderitem-createCost', 'Legg til kostnad') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.createCost(item);
						}
					});
				}

				if (me.canDelete(item)) {
					var deleteText = translationService.translate('web-workorder-purchaseOrderItem-delete', 'Slett konteringsobjekt');
					var enableDelete = true;
					if ($scope.model.Stage === 'History') {
						enableDelete = false;
						deleteText = translationService.translate('web-workorder-purchaseOrderItem-delete-woIsHistory', 'Kan ikke slette konteringsobjekt når AO er i historikk.');
					} else if ($scope.model.EndDate) {
						enableDelete = false;
						deleteText = translationService.translate('web-workorder-purchaseOrderItem-delete-woHasEndDate', 'Kan ikke slette konteringsobjekt når AO er fullført.');
					} else if ($scope.model.Stage === 'Project' && $scope.model.Project && $scope.model.Project.ClosedDate) {
						enableDelete = false;
						deleteText = translationService.translate('web-workorder-purchaseOrderItem-delete-projectIsCompleted', 'Kan ikke slette konteringsobjekt når AO er koblet til et fullført prosjekt.');
					} else if (item.IsInvoiced) {
						enableDelete = false;
						deleteText = translationService.translate('web-workorder-purchaseOrderItem-delete-isInvoiced', 'Kan ikke slette fakturert konteringsobjekt');
					}
					options.push({
						hasTopDivider: true,
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + deleteText + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.remove(item);
						},
						enabled: enableDelete
					});
				}
				return options;
			};
		}();

		$scope.costInfo = new function () {
			var me = this;

			this.columns = [];
			this.columns.addColumn = function (translationId, defaultText, property, propertyType) {
				this.push({ Position: this.length, Title: translationService.translate(translationId, defaultText), Property: property, PropertyType: propertyType });
				return this;
			};

			this.columns
				.addColumn('web-cost-InvoiceNumber', "Fakturanr.", 'InvoiceNumber', 'string')
				.addColumn('web-account-id', "Konto", 'Account.Id', 'string')
				.addColumn('web-account-description', "Beskrivelse", 'Account.Description', 'string')
				.addColumn('web-cost-amount', "Beløp", 'Amount', 'double')
				.addColumn('web-cost-InvoiceDate', "Fakturadato", 'InvoiceDate', 'date')
				.addColumn('web-supplier-description', "Leverandør", 'Supplier.Description', 'string')
				.addColumn('web-cost-vouchernumber', "Bilagsnr", 'VoucherNumber', 'string');
		}();

		var getCardTables = function () {
			$scope.sparePartInfo.initTable();
			$scope.purchaseOrderItemInfo.initTable();

			$scope.requestModel = null;
			if ($scope.hasReadAccess(repository.apiData.request.prefix)) {
				repository.GetPaginated(repository.apiData.request.url, 0, 1, { Id: 'asc' }, {
					PropertyFilter: [
						{ Property: 'GUID_WORK_ORDER', Operator: '=', Value: $scope.model.Guid },
					]
				}).then(function (result) {
					$scope.requestModel = result.List[0];
				});
			}
		};
		// #endregion cards

		var setProperties = function () {
			var workOrder = $scope.model;

			if (workOrder.Estate) $scope.modelDto.estate = workOrder.Estate.Caption;
			if (workOrder.Building) $scope.modelDto.building = workOrder.Building.Caption;
			if (workOrder.Equipment) $scope.modelDto.equipment = workOrder.Equipment.Caption;
			if (workOrder.Project) $scope.modelDto.project = workOrder.Project.Caption;
			if (workOrder.Area) $scope.modelDto.area = workOrder.Area.Caption;
			if (workOrder.Department) $scope.modelDto.department = workOrder.Department.Caption;
			if (workOrder.Supplier) $scope.modelDto.supplier = workOrder.Supplier.Caption;
			if (workOrder.Customer) $scope.modelDto.customer = workOrder.Customer.Caption;
			if (workOrder.InvoiceCustomer) $scope.modelDto.invoiceCustomer = workOrder.InvoiceCustomer.Description; // only show description #16128
			if (workOrder.ResourceResponsible) $scope.modelDto.resourceResponsible = workOrder.ResourceResponsible.Caption;
			if (workOrder.Person) $scope.modelDto.person = workOrder.Person.Caption;
		};

		var columns = [
			'Estate.Id', 'Estate.Description', 'Estate.Guid', 'Estate.GuidCostCenter', 'Estate.CostCenter.Id', 'Estate.CostCenter.Description',
			'Building.Id', 'Building.Description', 'Building.GuidCostCenter', 'Building.Guid', 'Building.CostCenter.Id', 'Building.CostCenter.Description',
			'Equipment.Id', 'Equipment.Description', 'Equipment.Guid', 'Equipment.CanHaveDowntime',
			'Area.Id', 'Area.Description', 'Area.Guid', 'Area.IsHousing', 'Area.GuidCostCenter', 'Area.CostCenter.Id', 'Area.CostCenter.Description',
			'Project.Id', 'Project.Description', 'Project.Guid', 'Project.ClosedDate', 'Project.GuidOwnerPerson', 'Project.GuidManagerPerson',
			'ResourceGroup.Id', 'ResourceGroup.Description', 'ResourceGroup.CostPrHour',
			'Department.Id', 'Department.Description',
			'Cause.Id', 'ActivityCategory.Description', 'ActivityCategory.UseInRequest',
			'Person.FirstName', 'Person.LastName', 'Person.Email',
			'Supplier.Id', 'Supplier.Description', 'ActivityGroup.Id',
			'Request.GuidEquipment', 'Request.GuidBuilding',
			'Customer.Id', 'Customer.Description',
			'PeriodicTask.GuidEquipment', 'PeriodicTask.GuidBuilding', 'PeriodicTask.GuidEstate', 'PeriodicTask.Period', 'PeriodicTask.Id', 'PeriodicTask.Description',
			'Priority.Description', 'Priority.HasDeadline', 'Priority.DeadlinePeriodNumber', 'Priority.DeadlinePeriodUnit', 'ResourceResponsible.FirstName', 'ResourceResponsible.LastName', 'ResourceResponsible.Email',
			'User.RealName', 'User1.RealName', 'User2.RealName',
			'InvoiceCustomer.Id', 'InvoiceCustomer.Description'
		];

		// gather data that are going to be passed to the API, which will use them during entity creation process
		var getPresets = function () {
			var defer = $q.defer();

			if ($scope.isUpdate)
				return $.Deferred().resolve(null).promise();// not relevant for already created entities

			// Make sure that the fieldrules have been loaded before setting presets
			registeredFieldService.getRegisteredField(repository.commonService.prefix.WorkOrder).then(function (registeredFields) {
				var customerRule = _.filter(registeredFields, { DomainFieldName: 'GuidCustomer' })[0];
				var presets = null;

				var setParameter = function (name, value) {
					if (!value)
						return false;
					if (!presets)
						presets = {};
					presets[name] = value;
					return true;
				};

				// difficult to know which infos to get from the API, and which ones from the UI, whose consistency over the different clients can be doubtful
				// -> preset contextual data and let the API ensure consistency from them during entity creation process,
				//    then let the UI fill in the rest (informational data).
				// 	  keep in mind that in case UI logic is no consistent with API one (unwittingly or not...), UI may alter data set by API

				var selectedBuilding = repository.commonService.getFilterData().selectedBuilding;
				if (selectedBuilding) {
					setParameter("GuidBuilding", selectedBuilding.Guid);
					if (selectedBuilding.Estate)
						setParameter("GuidEstate", selectedBuilding.Estate.Guid);
				}
				if (repository.commonService.getFilterData().selectedEstate)
					setParameter("GuidEstate", repository.commonService.getFilterData().selectedEstate.Guid);

				//setParameter("GuidCopyFrom", $stateParams.guidEntityToCopy);

				//if ($stateParams.guidEntityToCopy)
				//	queryString += "&GuidCopyFrom=" + $stateParams.guidEntityToCopy;
				if ($stateParams.showPersistantData) {
					var persistentData = repository.persistedData.getPersistedData('workOrder.create');
					if (persistentData) {
						setParameter("GuidEstate", persistentData.guidEstate);
						setParameter("GuidBuilding", persistentData.guidBuilding);
						setParameter("GuidArea", persistentData.guidArea);
						setParameter("GuidEquipment", persistentData.guidEquipment);
						setParameter("GuidProject", persistentData.guidProject);

						if (customerRule && !customerRule.Hidden)
							setParameter("GuidCustomer", persistentData.guidCustomer);

						if (persistentData.model) {
							setParameter("GuidResourceGroup", persistentData.model.GuidResourceGroup);
							setParameter("GuidResponsiblePerson", persistentData.model.GuidResponsiblePerson);
							setParameter("GuidDepartment", persistentData.model.GuidDepartment);
							setParameter("GuidCause", persistentData.model.GuidCause);
							setParameter("GuidActivityCategory", persistentData.model.GuidActivityCategory);
							setParameter("GuidPriority", persistentData.model.GuidPriority);
						}

						setParameter("GuidRequest", persistentData.guidRequest);
					}
				}

				var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
				if (webMenuParams && webMenuParams.Prefill) {
					webMenuParams.Prefill.forEach(function (prefill) {
						if (typeof (prefill.Value) === 'object') {
							switch (prefill.Property) {
								case 'Building':
								case 'Equipment':
								case 'Area':
								case 'ResourceResponsible':
								case 'ActivityCategory':
								case 'Priority':
									setParameter("Guid" + prefill.Property, prefill.Value.Guid);
									break;
							}
						} else if (prefill.Value === '@CurrentBuilding') {
							var building = repository.commonService.getFilterData().selectedBuilding;
							if (building.Guid) {
								setParameter("GuidBuilding", building.Guid);
							}
						} else if (prefill.Value === '@LoggedInPerson') {
							setParameter("GuidResponsiblePerson", repository.authService.getUserData().guidPerson);
						}/* else {
							$scope.model[prefill.Property] = prefill.Value;// considered as informational only (with no consequence on creation logic) -> fill them in after creation (onCreate())
						}*/
					});
				}
				defer.resolve(presets);
			});

			return defer.promise;
		};

		// complete entity initialization using informational data that may have been passed through local UI objects (mainly data related to DTO)
		var onCreate = function () {
			setProperties();
			var workOrder = $scope.model;
			$scope.canEditProjectReference = true;
			// fill in all other data that were not in setProperties()
			if ($stateParams.showPersistantData) {
				var persistentData = repository.persistedData.getPersistedData('workOrder.create');

				if (persistentData) {
					if (persistentData.defaultDescription) workOrder.Description = persistentData.defaultDescription;
					if (persistentData.defaultReference) workOrder.Reference = persistentData.defaultReference;
					if (persistentData.defaultText) workOrder.ExplanatoryText = persistentData.defaultText;
					if (persistentData.defaultStartDate) workOrder.StartDate = persistentData.defaultStartDate;

					if (persistentData.guidRequest) {
						generatedFrom = repository.commonService.prefix.Request;
						for (var countCombo = 1; countCombo <= 40; countCombo++) {
							var fieldCombo = 'Combo' + String(countCombo).padStart(2, '0');
							if (persistentData[fieldCombo]) workOrder[fieldCombo] = persistentData[fieldCombo];
						}
						for (var countDate = 1; countDate <= 30; countDate++) {
							var fieldDate = 'Date' + String(countDate).padStart(2, '0');
							if (persistentData) workOrder[fieldDate] = persistentData[fieldDate];
						}

						for (var countText = 1; countText <= 30; countText++) {
							var fieldText = 'Text' + String(countText).padStart(2, '0');
							if (persistentData[fieldText]) workOrder[fieldText] = persistentData[fieldText];
						}
						for (var countNumber = 1; countNumber <= 10; countNumber++) {
							var fieldNumber = 'Number' + String(countNumber).padStart(2, '0');
							if (persistentData[fieldNumber]) workOrder[fieldNumber] = persistentData[fieldNumber];
						}
					}

					if (persistentData.fromDeviation) {
						workOrder.Deviations = [];
						persistentData.guidSelections.forEach(function (guid) {
							workOrder.Deviations.push({ Guid: guid });
						});

						generatedFrom = repository.commonService.prefix.Deviation;

						if (persistentData.guidSelections.length === 1 && persistentData.enumTypeGuid) {
							repository.getSingle(repository.apiData.deviationType.url, persistentData.enumTypeGuid).then(function (result) {
								if (result && result.Data) {
									workOrder.Description = workOrder.Description + " - " + result.Data.Id;
								}
							});
						}
					}

					if (persistentData.fromCondition) {
						workOrder.Conditions = [];
						persistentData.guidSelections.forEach(function (guid) {
							workOrder.Conditions.push({ Guid: guid });
						});

						generatedFrom = repository.commonService.prefix.Condition;
					}

					if (persistentData.fromEquipment) {
						generatedFrom = repository.commonService.prefix.Equipment;
					}

					if (persistentData.fromOperationalMessage) {
						generatedFrom = repository.commonService.prefix.OperationalMessage;

						workOrder.OperationalMessages = [];
						persistentData.guidSelections.forEach(function (guid) {
							workOrder.OperationalMessages.push({ Guid: guid });
						});
					}

					if (persistentData.guidProject) {
						generatedFrom = repository.commonService.prefix.Project;
					}
				}
			}

			$scope.modelDto.CompletionRate = workOrder.CompletionRate || 0;
			$timeout(function () { $scope.activateAutocomplete = true; }, 250);

			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) !== 'object' && prefill.Value !== '@CurrentBuilding' && prefill.Value !== '@LoggedInPerson')
						$scope.model[prefill.Property] = prefill.Value;
				});
			}

			// In order to use prefilled value. Might want to run autocomplete twice for this.
			if (workOrder.Priority)
				$scope.onPrioritySelect(workOrder.Priority);

			// Description might have been prefilled with longer text than allowed, so we cut it off.
			if (workOrder.Description)
				workOrder.Description = workOrder.Description.substr(0, fieldLengths.Description);
		};

		var onGet = function (response) {
			var workOrder = response.Data ? response.Data : response;
			$scope.model = workOrder;
			originalStartDate = $scope.model.StartDate;
			$scope.extendWorkOrderType = getWorkOrderType();
			if ($scope.isUpdate) {
				getCardTables();

				$scope.enableWebNavigation = repository.enableWebNavigation;
				$scope.isClosable = ($scope.model.PurchaseOrderStatus === 'Proposal' || $scope.model.PurchaseOrderStatus === 'NoOrder');

				$scope.modelDto.CompletionRate = Number(($scope.model.CompletionRate * 100).toFixed(0));

				setProperties();
				updateAccess();

				if (workOrder.GuidProject) {
					$scope.canEditProjectReference = workOrder.Project.GuidManagerPerson === $scope.userInfo.guidPerson || workOrder.Project.GuidOwnerPerson === $scope.userInfo.guidPerson || $scope.userInfo.isSystemAdministrator;
				}
				else
					$scope.canEditProjectReference = true;

				isDirtyEndDate = false;
				$scope.reloads.documents = true;
				$scope.reloads.deviations = true;
				$scope.reloads.alarmLogs = true;
				$scope.reloads.controlLists = true;
				$scope.reloads.economyInfo = true;
				$scope.reloadConditions = true;
				$timeout(function () { $scope.activateAutocomplete = true; }, 250);
			}
			else
				onCreate();
		};

		var getEntity = function () {
			var defer = $q.defer();
			// for either update (with existing GUID) or insert (with default null GUID), fetch the whole entity to get proper FieldRules, which do not only depend on the entity type, but also on the current entity properties
			// furthermore, some fields may be dynamically initialized for new entities, what may be difficult to maintain in front end code
			// (there is not performance loss compared to the old solution, since a new object already needed a request to fetch field rules anyway)
			var queryString = JSON.stringify(columns);
			if ($stateParams.guidEntityToCopy)
				queryString += "&GuidCopyFrom=" + $stateParams.guidEntityToCopy;
			getPresets().then(function (presets) {
				repository.getMainModel(repository.apiData.workOrder.url, ($scope.isUpdate ? $stateParams.guid : "00000000-0000-0000-0000-000000000000"), queryString, presets ? JSON.stringify(presets) : null).then(function (response) {
					onGet(response);
					defer.resolve(response);
				}).catch(function (error) {
					repository.growl(error, 'danger');
					defer.reject();
				});
			});

			return defer.promise;
		};

		var updateEntity = function () {
			// properties to parent entities may contain incomplete (ex. when fetched using columns), outdated or unreliable data, that may cause errors when deserialized on server side
			// furthermore, they would send an unnecessary heavy payload
			// -> unless explicitly required (seldom), reset them to be sure they are correctly reloaded if needed
			var defer = $q.defer();

			// get all (not null) parent properties currently defined for the entity (not all "registered" properties of the BL class may exist in the JS model)
			// assume that no parent property is defined in the JS entity without its FK counterpart (= for all 'XXX' there is a 'GuidXXX')
			var parentProperties = [];
			for (var propertyName in $scope.model) {
				if (!propertyName.startsWith('Guid'))
					continue;
				var parentPropertyName = _.capitalize(propertyName.substring(4));
				var parent = $scope.model[parentPropertyName];
				if (parent)
					parentProperties.push(parentPropertyName);
			}

			// get a copy of the original entity whose parent entities are reset (to be passed as payload for update function)
			var payloadEntity = angular.copy($scope.model);
			parentProperties.forEach(function (field) {
				delete payloadEntity[field];
			});

			var executeUpdate = function () {
				(($scope.isUpdate || payloadEntity.RequestAction) ? repository.updateSingleDictionary : repository.createSingleDictionary)(repository.apiData.workOrder.url, payloadEntity).then(
					function (response) {
						// on returning from update, refresh the model with any up-to-date parent property sent by the API
						// NB: the API may not send all properties currently loaded in the model (if the BL did not need them, they were not loaded, just the GUID is valid)

						// instead of copying every single property to the model, while skipping parent properties that are set in the model but not in the response,
						// it is more convenient to first copy from the model to the response every parent property set in the model but missing in the response (provided its GUID is valid), then copy the whole response object back to the model
						parentProperties.forEach(function (field) {
							if (!response[field] && response['Guid' + field] && $scope.model[field])
								response[field] = angular.copy($scope.model[field]);
						});
						onGet(response);
						defer.resolve(response);
					}).catch(function (error) {
						defer.reject(error);
					});
			};

			executeUpdate();
			return defer.promise;
		};

		getEntity();

		$scope.onResourceGroupSelect = function (resourceGroup) {
			if (!resourceGroup) {
				$scope.model.GuidResourceGroup = "";
				return;
			}

			$scope.model.GuidResourceGroup = resourceGroup.Guid;
		};

		$scope.onResponsiblePersonSelect = function (person) {
			if (!person) {
				$scope.model.GuidResponsiblePerson = "";
				return;
			}
			$scope.activateAutocomplete = false;
			$scope.model.GuidResponsiblePerson = person.Guid;

			if ($localStorage.generalOptions.OverrideActWithPersInfo) {
				if ($scope.model.GuidResourceGroup !== person.GuidResourceGroup) {
					$scope.model.GuidResourceGroup = person.GuidResourceGroup;
					$scope.model.ResourceGroup = { Guid: person.GuidResourceGroup, Caption: person.ResourceGroup ? person.ResourceGroup.Caption : null };
				}
				if ($scope.model.GuidDepartment !== person.GuidDepartment) {
					$scope.model.GuidDepartment = person.GuidDepartment;
					$scope.modelDto.department = person.Department ? person.Department.Caption : null;
				}
			}
			$timeout(function () { $scope.activateAutocomplete = true; }, 250);
		};

		$scope.onEstateSelect = function (estate) {
			if (!estate) {
				$scope.model.GuidEstate = "";
				return;
			}

			if ($scope.model.GuidEstate !== estate.Guid) {
				$scope.model.GuidBuilding = "";
				$scope.modelDto.building = "";
				$scope.model.GuidEquipment = "";
				$scope.modelDto.equipment = "";
				$scope.model.GuidArea = "";
				$scope.modelDto.area = "";
			}

			$scope.model.GuidEstate = estate.Guid;
		};

		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.model.GuidBuilding = "";
				return;
			}

			if ($scope.model.GuidBuilding !== building.Guid) {
				$scope.model.GuidEquipment = "";
				$scope.modelDto.equipment = "";
				$scope.model.GuidArea = "";
				$scope.modelDto.area = "";
			}

			$scope.model.GuidBuilding = building.Guid;

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "" || $scope.model.GuidEstate !== building.GuidEstate) {
				$scope.model.GuidEstate = building.GuidEstate;
				$scope.modelDto.estate = building.Estate.Id + " - " + building.Estate.Description;
			}
		};

		$scope.onEquipmentSelect = function (equipment) {

			if (!equipment) {
				$scope.model.GuidEquipment = "";
				return;
			}
			$scope.model.GuidEquipment = equipment.Guid;

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "") {
				$scope.model.GuidEstate = equipment.GuidEstate || equipment.Building.Estate.Guid;
				$scope.modelDto.estate = equipment.Building.Estate.Id + " - " + equipment.Building.Estate.Description;
			}

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "") {
				$scope.model.GuidBuilding = equipment.GuidBuilding;
				$scope.modelDto.building = equipment.Building.Id + " - " + equipment.Building.Description;
			}

			if (equipment.GuidArea) {
				$scope.model.GuidArea = equipment.GuidArea;
				$scope.modelDto.area = equipment.Area.Id + " - " + equipment.Area.Description;
			}
		};

		$scope.onAreaSelect = function (area) {
			if (!area) {
				$scope.model.GuidArea = "";
				return;
			}

			$scope.model.GuidArea = area.Guid;

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "") {
				$scope.model.GuidBuilding = area.GuidBuilding;
				$scope.modelDto.building = area.Building.Id + " - " + area.Building.Description;
			}

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "") {
				$scope.model.GuidEstate = area.GuidEstate || area.Building.Estate.Guid;
				$scope.modelDto.estate = area.Building.Estate.Id + " - " + area.Building.Estate.Description;
			}
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};

			var estateFieldRule = registeredFieldService.getLocalRegisteredField(repository.apiData.workOrder.prefix, 'GuidEstate');
			var isEstateHidden = estateFieldRule && estateFieldRule.Hidden;

			switch (filterName) {
				case "estate":
					return filter;
				case "activityCategory":
					return { PropertyFilter: [{ Property: 'UseInWorkOrder', Operator: '=', Value: 'true' }, { Property: 'IsAbstract', Operator: '=', Value: '0' }] };
				case "building":
					if ($scope.model.GuidEstate && !isEstateHidden) {
						filter.PropertyFilter = [
							{ Property: 'GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }
						];
						return filter;
					}

					return filter;
				case "equipment":
					filter = { GuidBuilding: $scope.model.GuidBuilding, IsMaintainable: 1, PropertyFilter: [] };
					if ($scope.model.GuidEstate && !$scope.model.GuidBuilding && !isEstateHidden) {
						filter.PropertyFilter = [{ Property: 'Building.GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }];
					}
					filter.PropertyFilter.push({ Property: 'IsTemplate', Operator: '=', Value: false });
					return filter;
				case "area":
					filter = { GuidBuilding: $scope.model.GuidBuilding };
					if ($scope.model.GuidEstate && !isEstateHidden) {
						filter.PropertyFilter = [{ Property: 'Building.GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }];
					}
					return filter;
				case "person":
					if ($localStorage.generalOptions.UseOnlyActivPersonOnActiv) {
						filter.DoesMaintenanceTasks = 1;
					}

					if ($localStorage.generalOptions.UseOnlyPersInBldOnActiv) {
						filter.GuidBuildingResponsible = $scope.model.GuidBuilding;
						//Looks like this is
						//filter.GuidResourceGroup = $scope.model.GuidResourceGroup;
					}

					return filter;
				case "resourceResponsible":
					filter.DoesMaintenanceTasks = true;
					return filter;
				case "resourceGroup":
					filter.PropertyFilter = [{ Property: 'DoesMaintenanceTasks', Operator: '=', Value: 'true' }];
					return filter;
				case "supplier":
					filter.PropertyFilter = [{ Property: 'IsDeactivated', Operator: '=', Value: 'false' }];
					return filter;
				case "priority":
					filter.PropertyFilter = [{ Property: 'EntityType', Operator: '=', Value: 6 }];
					return filter;
				case "cause":
					if ($scope.model.GuidResourceGroup) {
						filter.FilterOnResourceGroup = $scope.model.GuidResourceGroup;
					}
					return filter;
				case "project":
					if ($localStorage.userData.isSystemAdministrator) {
						filter.PropertyFilter = [{ Property: 'IsTemplate', Operator: '=', value: 0, Operand: 'AND' }, { Property: 'ClosedDate', Operator: '=', value: null, Operand: 'AND' }];
					} else {
						filter.PropertyFilter = [
							{ Property: 'GuidOwnerPerson', Operator: '=', value: $localStorage.userData.guidPerson, Operand: 'AND' },
							{ Property: 'ClosedDate', Operator: '=', value: null, Operand: 'AND' },
							{ Property: 'IsTemplate', Operator: '=', value: 0, Operand: 'AND' },
							{ Property: 'GuidManagerPerson', Operator: '=', value: $localStorage.userData.guidPerson, Operand: 'OR' },
							{ Property: 'ClosedDate', Operator: '=', value: null, Operand: 'AND' },
							{ Property: 'IsTemplate', Operator: '=', value: 0, Operand: 'AND' },
						];
					}
					return filter;
			}
		};

		$scope.completeWorkOrder = function () {
			$modal.open({
				templateUrl: 'app/workOrder/views/completeWorkOrderModal.html',
				controller: 'CompleteWorkOrderModalController',
				scope: $scope
			}).result.then(function (result) { //Ok
				$scope.newResourceGroup = { RealTime: result.RealTime, Note: result.Note, GuidHourType: result.GuidHourType };
				$scope.createWorkOrder('workOrder.list', true);
			}, function (reason) { //Dismiss
				if (reason === 'canceled')
					getEntity();
			});
		};

		$scope.activateWorkOrder = function () {
			if ($scope.model.Stage !== 'Project')
				return false;

			workOrderService.activateWorkOrders([$scope.model.Guid])
				.then(function (result) {
					$scope.goBack('workOrder.edit', { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
					repository.growl(translationService.translate('web-workorder-activate-success', 'Arbeidsordren er aktivert'), 'success');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
		};

		$scope.closeWorkOrder = function () {
			repository.updateSingleDictionary(repository.apiData.workOrder.endpoint.closePayment, { Guid: $scope.model.Guid, GuidUser: repository.authService.getUserData().guidUser })
				.then(function (result) {
					repository.growl(translationService.translate('web-workorder-close-success', 'Arbeidsordren er lukket'), 'success');
					$scope.goBack('workOrder.edit', { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
				}, function (error) {
					repository.growl(error, 'danger');
				});
		};

		$scope.releaseWorkOrder = function () {
			$scope.model.EndDate = null;
			$scope.createWorkOrder('workOrder.edit');
		};

		var convertToDate = function (date) {
			return typeof date === "string" ? new Date(date) : date || null;
		};

		// Boolean to stop possibility of recursive loop when re-saving on certain errors.
		var hasShowError = false;
		$scope.createWorkOrder = function (destination, showErrorAsSwal) {
			hasShowError = false;
			var workOrder = $scope.model;

			workOrder.StartDate = convertToDate(workOrder.StartDate);
			workOrder.PrintedDate = convertToDate(workOrder.PrintedDate);
			workOrder.EndDate = convertToDate(workOrder.EndDate);
			workOrder.ReportedEndDate = convertToDate(workOrder.ReportedEndDate);
			workOrder.DueDate = convertToDate(workOrder.DueDate);
			workOrder.CompletionRate = $scope.modelDto.CompletionRate / 100;

			var successNavigate = function (destination, guid) {
				if ($scope.isModal && destination === 'workOrder.edit')
					return;
				else if ($scope.isModal)
					$scope.cancel();
				else
					$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('web-workorder-update', 'Arbeidsordren ble oppdatert') : translationService.translate('web-workorder-created', 'Arbeidsordren er opprettet'), 'success');
				var guid = $scope.isUpdate ? workOrder.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);

				if ($scope.isUpdate &&
					isDirtyEndDate &&
					workOrder.PeriodicTask &&
					workOrder.PeriodicTask.Period === 'HourOfUse') {

					//show operating hours based on equipment guid
					$modal.open({
						templateUrl: 'app/equipment/views/equipmentOperatingHoursModal.html',
						controller: 'EquipmentOperatingHoursController',
						size: 'sm',
						resolve: {
							params: function () { return { GuidEquipment: workOrder.GuidEquipment, ReadDate: new Date(), GuidPeriodicTask: workOrder.GuidPeriodicTask }; }
						}
					}).result.then(function () {
						successNavigate(destination, guid);
					},
						function () {
							successNavigate(destination, guid);
						});
				} else {
					successNavigate(destination, guid);
				}
			};

			var error = function (error) {
				//reset trying to complete workorder. we should probably have a correct response from api and change it accordingly instead of guessing completion of workorder
				if (workOrder.EndDate && workOrder.Stage !== 'History')
					delete workOrder.EndDate;

				var errorMessage = typeof (error) === "string" ? error : error.Data.Message;

				if (!hasShowError && errorMessage.includes(translationService.translate('api-workOrder-checkStateStatusOfWorkOrder-newControlList', 'En eller flere kontrollister blir lagt til p.g.a kontrolliste regler. Vennligst vent litt og se igjennom de nye kontrollistene før du prøver å fullføre.'))) {
					// Save again if new controllists should be added.
					hasShowError = true;
					destination = 'workOrder.edit';
					updateEntity().then(success, error);
				}

				if (!showErrorAsSwal) repository.growl(errorMessage, 'danger');
				else {
					swal(
						"",
						errorMessage.split(/, (?=[A-Z])/).join('\n\n'),
						"warning"
					);
				}
				hasShowError = true;
			};

			var runUpdate = function () {
				if ($scope.newResourceGroup && $scope.newResourceGroup.RealTime && $scope.userInfo.guidPerson) {
					var columns = ["ResourceGroup.CostPrHour"];
					repository.getSingle(repository.apiData.person.url, $scope.userInfo.guidPerson, JSON.stringify(columns)).then(
						function (result) {
							var person = result.Data;
							var costPrHour = person.ResourceGroup.CostPrHour;
							var registeredDate = $scope.model.EndDate ? $scope.model.EndDate : new Date().toISOString();
							var resourceGroup = {
								GuidWorkOrder: workOrder.Guid,
								GuidPerson: person.Guid,
								GuidHourType: $scope.newResourceGroup.GuidHourType,
								GuidPersonResourceGroup: person.GuidResourceGroup,
								GuidResourceGroup: person.GuidResourceGroup,
								RealTime: $scope.newResourceGroup.RealTime,
								Note: $scope.newResourceGroup.Note,
								EstimatedTime: 0,
								EstimatedCost: 0,
								RealCost: costPrHour * $scope.newResourceGroup.RealTime,
								RegisteredDate: registeredDate
							};
							repository.createSingleDictionary(repository.apiData.workOrderXResourceGroup.url, resourceGroup).then(function (result) {
								$scope.newResourceGroup = null;
								$scope.resourceGroupInfo.table.reload();
								updateEntity().then(success, error);
							}).catch(function (result) {
								repository.growl(result, 'danger');
							});
						});
					return;
				}

				if ($scope.isUpdate)
					updateEntity().then(success, error);
				else {
					workOrder.GeneratedFrom = generatedFrom;
					if (workOrder.EndDate &&
						(generatedFrom === repository.commonService.prefix.Deviation ||
							generatedFrom === repository.commonService.prefix.Condition)) {
						swal({
							title: translationService.translate('web-swal-error-Wo-FromInvalid-title', 'Direkte opprettelse og lukking av AO er ikke mulig'),
							text: translationService.translate('web-swal-error-Wo-FromInvalid-description', 'Det er ikke mulig å opprette en arbeidsordre med ferdigdato ferdig utfylt når arbeidsordre blir opprettet fra avvik eller tilstand'),
							type: "warning",
							showCancelButton: true,
							confirmButtonColor: "#f44336",
							confirmButtonText: translationService.translate('web-swal-error-Wo-FromInvalid-confirm', 'Opprett uten ferdigmelding'),
							cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
							closeOnConfirm: true
						},
							function () {
								window.onkeydown = null;
								window.onfocus = null;
								delete workOrder.EndDate;
								$scope.createWorkOrder(destination);
							});
					}
					else
						updateEntity().then(success, error);
				}
			};

			var shouldActivate = function () {
				var deferred = $q.defer();
				// Check if there has been changed that should only be permitted for active workOrders.
				if ($scope.isUpdate && workOrder.Stage === 'Project' && (workOrder.EndDate || workOrder.CompletionRate !== 0)) {
					var text = translationService.translate('web-swal-workOrder-activate-message-following', 'Følgende handlinger forrårsaket denne meldingen: \n');
					if (workOrder.EndDate)
						text += translationService.translate('web-workOrder-EndDate', 'Sluttdato') + '\n';
					if (workOrder.CompletionRate !== 0)
						text += translationService.translate('web-workOrder-CompletionRate', 'Fullføringsgrad') + '\n';

					workOrderService.shouldActivateWorkOrder(null, text)
						.then(function (result) {
							workOrder.Stage = 'Active';
							deferred.resolve();
						}, function (error) {
							if (error)
								repository.growl(error);
							deferred.reject();
						});
				}
				else
					deferred.resolve();
				return deferred.promise;
			};

			if (moment(workOrder.EndDate).isAfter(moment(new Date()))) {
				swal({
					title: translationService.translate('web-workOrder-endDate-forwardInTime', 'Ferdigdato er satt frem i tid'),
					text: translationService.translate('web-workOrder-endDate-continue', 'Fortsette?'),
					type: 'warning',
					confirmButtonText: 'Fortsett',
					showCancelButton: true,
					cancelButtonText: 'Avbryt',
				}, function (result) {
					swal.close();
					// Need timeout to allow the current swal to close, else it will cause the next swal to automatically close
					$timeout(function () {
						if (result) {
							shouldActivate().then(function () { runUpdate(); });
						}
					}, 100);

				});
			} else {
				shouldActivate().then(function () { runUpdate(); });
			}
		};

		$scope.sendEmail = function () {
			var attachments = $scope.documents.concat($scope.model.Images);

			var onNewDocument = function () {
				$scope.reloads.forceDocuments = false;
				$timeout(function () {
					$scope.reloads.forceDocuments = true;
				}, 250);
			};

			var reloadImages = function () {
				return repository.getMainModel(repository.apiData.workOrder.url, $stateParams.guid).then(function (response) {
					$scope.model.Images = response.Data.Images;
					return $scope.model.Images;
				});
			};

			$modal.open({
				templateUrl: 'app/common/views/emailModal.html',
				controller: 'EmailModalController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							entity: 'WorkOrder',
							columns: columns,
							model: $scope.model,
							attachments: attachments,
							getAllDocuments: $scope.counts.documentTotalCount > 10,
							prefillSubjectWithCaption: true,
							onNewDocument: onNewDocument,
							reloadImages: reloadImages
						};
					}
				}
			}).result.then(function () {
				$scope.reloads.alarmLogs = true;
			});
		};

		$scope.removeWorkOrder = function () {
			var text = $scope.model.GuidRequest ? translationService.translate('web-swal-workorder-hasrequestmessage', 'Arbeidsordren er koblet opp mot en melding. Ønsker du fortsatt å slette arbeidsordren?') : translationService.translate('web-swal-workorder-message', 'Arbeidsordren vil bli permanent fjernet!');

			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: text,
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-workorder-button-confirm', 'Ja, slett arbeidsordren!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.workOrder.url, $scope.model.Guid)
					.then(function () {
						swal.close();
						$scope.goBack('workOrder.list', { menuGuid: $scope.navigation.params.menuGuid });
						repository.growl(translationService.translate('web-workOrder-growl-deleted', 'Arbeidsordren har blitt fjernet'), 'success');
					});
			});
		};

		$scope.onPrioritySelect = function (priority) {
			if (priority) {
				$scope.model.GuidPriority = priority.Guid;
				if (priority.HasDeadline) {
					if (!$scope.model.StartDate) $scope.model.StartDate = new Date();
					$scope.model.DueDate = enumService.getTimeUnitDate($scope.model.StartDate, priority.DeadlinePeriodUnit, priority.DeadlinePeriodNumber);
					$scope.restrictDueDate = true;
				}
			} else {
				$scope.model.GuidPriority = null;
				$scope.restrictDueDate = false;
			}
		};

		$scope.printWorkOrder = function () {
			$scope.model.PrintedDate = new Date();
			$scope.createWorkOrder('workOrder.edit');
			var url = $scope.navigation.href('report.print', { reportName: 'PrintWorkOrder', reportParams: JSON.stringify({ Guid: $scope.model.Guid }) });
			$window.open(url, '_blank');
		};

		$scope.onActivityCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidActivityCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidActivityCategory = category.Guid;
		};

		$scope.createNewRequest = function () {

			var newRequest = {
				model: angular.copy($scope.model),
				building: $scope.modelDto.building,
				area: $scope.modelDto.area,
				equipment: $scope.modelDto.equipment,
				guidWorkOrder: $scope.model.Guid,
				resourceGroup: $scope.model.ResourceGroup ? $scope.model.ResourceGroup.Caption : null,
				responsiblePerson: $scope.modelDto.person,
				department: $scope.modelDto.department,
				cause: $scope.model.Cause ? $scope.model.Cause.Id : null,
				priority: $scope.model.Priority ? $scope.model.Priority.Description : null,
			};

			if ($scope.model.ActivityCategory && $scope.model.ActivityCategory.UseInRequest === true) {
				for (var countCombo = 1; countCombo <= 40; countCombo++) {
					var fieldCombo = 'Combo' + String(countCombo).padStart(2, '0');
					if ($scope.model[fieldCombo]) newRequest[fieldCombo] = $scope.model[fieldCombo];
				}
				for (var countDate = 1; countDate <= 30; countDate++) {
					var fieldDate = 'Date' + String(countDate).padStart(2, '0');
					if ($scope.model[fieldDate]) newRequest[fieldDate] = $scope.model[fieldDate];
				}

				for (var countText = 1; countText <= 30; countText++) {
					var fieldText = 'Text' + String(countText).padStart(2, '0');
					if ($scope.model[fieldText]) newRequest[fieldText] = $scope.model[fieldText];
				}
				for (var countNumber = 1; countNumber <= 10; countNumber++) {
					var fieldNumber = 'Number' + String(countNumber).padStart(2, '0');
					if ($scope.model[fieldNumber]) newRequest[fieldNumber] = $scope.model[fieldNumber];
				}
			}
			else {
				newRequest.model.GuidActivityCategory = "";
				newRequest.model.ActivityCategory = null;
			}

			repository.persistedData.setPersistedData('request.create', newRequest);
			$scope.navigation.go('request.create', { showPersistantData: true }, { reload: true });
		};

		$scope.addExistingRequest = function () {
			$modal.open({
				templateUrl: 'app/common/views/singleSelectModal.html',
				controller: 'SingleSelectModalController',
				size: 'lg',
				resolve: {
					modalParams: function () {
						var propertyFilter = [
							{ Property: 'GuidWorkOrder', Operator: '=', Value: '' }, { Property: 'Status', Operator: '<>', Value: '7' },
							{ Property: 'GuidWorkOrder', Operator: '=', Value: '' }, { Property: 'Status', Operator: '<>', Value: '3' }
						];
						if ($scope.model.GuidBuilding)
							propertyFilter.push({ Property: 'GuidBuilding', Operator: '=', Value: $scope.model.GuidBuilding, allowToggle: true, filterValue: $scope.modelDto.building, isDisabled: false });
						if ($scope.model.GuidEquipment)
							propertyFilter.push({ Property: 'GuidEquipment', Operator: '=', Value: $scope.model.GuidEquipment, allowToggle: true, filterValue: $scope.modelDto.equipment, isDisabled: false });
						if ($scope.model.GuidArea)
							propertyFilter.push({ Property: 'GuidArea', Operator: '=', Value: $scope.model.GuidArea, allowToggle: true, filterValue: $scope.modelDto.area, isDisabled: false });

						return {
							title: translationService.translate('web-workOrder-addExistingRequest', 'Legg til eksisterende melding'),
							url: repository.apiData.request.url,
							sorting: { Id: 'desc' },
							filter: { PropertyFilter: propertyFilter },
							columns: [
								{ title: translationService.translate('web-request-id', 'Id'), property: 'Id' },
								{ title: translationService.translate('web-building-id'), property: 'Building.Id' },
								{ title: translationService.translate('web-building-description'), property: 'Building.Description' },
								{ title: translationService.translate('web-request-description'), property: 'Description' },
								{ title: translationService.translate('web-request-contactperson'), property: 'ContactPerson' },
								{ title: translationService.translate('web-request-status'), property: 'Status', PropertyType: 'enum.requeststatus' },
								{ title: translationService.translate('web-request-duedate'), property: 'DueDate' },
								{ title: translationService.translate('web-request-telephone'), property: 'Telephone' }
							]
						};
					}
				}
			})
				.result.then(function (item) {
					$scope.model.GuidRequest = item.Guid;
					$scope.createWorkOrder('workOrder.edit');
				},
					function (msg) {
					});
		};

		$scope.navigate = function () {
			if ($scope.isModal) {
				$scope.cancel();
			} else {
				$scope.goBack('workOrder.list', { menuGuid: $scope.navigation.params.menuGuid });
			}
		};

		$scope.cancel = function () {
			$modalStack.dismissAll();
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		$scope.generateCalendarEvent = function () {
			repository.getSingle(repository.apiData.workOrderCalendar.url, $scope.model.Guid, null)
				.then(function (response) {
					var blob = new Blob([response], { type: "text/calendar" });
					if (window.navigator.msSaveOrOpenBlob) {
						window.navigator.msSaveBlob(blob, $scope.model.Id + ".ics");
					}
					else {
						var element = document.createElement('a');
						element.style.display = 'none';
						element.href = window.URL.createObjectURL(blob);
						element.download = $scope.model.Id + '.ics';
						document.body.appendChild(element);
						element.click();
						document.body.removeChild(element);
						window.URL.revokeObjectURL(element.href);
					}
				}, function (error) {
					repository.growl(error, 'danger');
				});
		};

		var getMenuLink = function (url, icon, text) {
			return {
				html: '<a class="dropdown-item" tabindex= "-1" href="' + url + '" ><i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ' + text + '</a>',
				isHref: true
			};
		};

		if ($scope.isUpdate) {
			signalR.on('UpdatedWorkOrderSums' + $stateParams.guid, function (updatedWorkOrder) {
				Object.keys(updatedWorkOrder).forEach(function (key) {
					$scope.model[key] = updatedWorkOrder[key];
				});
				$scope.reloads.economyInfo = false;
				$timeout(function () { $scope.reloads.economyInfo = true; }, 250);
			});

			$scope.$on('$destroy', function () {
				signalR.off('UpdatedWorkOrderSums' + $stateParams.guid);
			});
		}
	}
})();
